import { MedplumClient } from '@medplum/core'
import { captureException, SentrySources, TransactionNames } from 'utils/sentry'
import { getErrorInstance, getGraphQLErrorMessage } from './helpers'

export const ALLOWED_STATUS_CODES = [200, 201, 202, 401, 403, 409]

export const ALLOWED_MEDPLUM_ERRORS = ['Failed to fetch']

const fetchMiddleware = async (url: string, options?: any): Promise<Response> => {
  let response
  try {
    response = await fetch(url, options)
    if (response?.status && !ALLOWED_STATUS_CODES.includes(parseInt(response?.status))) {
      captureException(new Error(`Fetch error with status ${response?.status}`), {
        tags: { source: SentrySources.MEDPLUM_FETCH_MIDDLEWARE, url },
        extras: { params: options },
        transactionName: TransactionNames.FETCH
      })
    }

    return response
  } catch (error) {
    if (!ALLOWED_MEDPLUM_ERRORS.some((allowedError) => error.message.includes(allowedError))) {
      captureException(getErrorInstance(error), {
        tags: { source: SentrySources.MEDPLUM_FETCH_MIDDLEWARE, url },
        extras: { params: options, errors: [error] },
        transactionName: TransactionNames.FETCH
      })
    }

    throw error
  }
}

class MedplumClientMiddleware extends MedplumClient {
  async graphql(
    query: string,
    operationName?: string | null,
    variables?: any,
    options?: RequestInit
  ): Promise<any> {
    try {
      const result = await super.graphql(query, operationName, variables, options)

      const { data, errors, error } = result

      const errorMessage = `A Graphql query error is returned from Medplum: ${getGraphQLErrorMessage(
        errors
      )}`

      if (errors || error) {
        captureException(new Error(errorMessage), {
          tags: { source: SentrySources.MEDPLUM_GRAPHQL_QUERY },
          extras: {
            info: {
              result: data
            },
            errors: errors || [{ message: error }],
            query: { query, operationName, variables }
          }
        })
      }

      return result
    } catch (error) {
      throw new Error('Error in useQuery', { cause: error })
    }
  }
}

export const medplum = new MedplumClientMiddleware({
  // Use Next.js fetch
  fetch: async (url: string, options?: any) => await fetchMiddleware(url, options),
  cacheTime: 0
})
