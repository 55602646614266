import { FC, ReactElement, useEffect } from 'react'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useMedplum } from '@medplum/react'
import { formatPrimaryName } from 'utils/names'

interface UserContextProviderProps {
  children: ReactElement
}

const FeatureFlagUserContextProvider: FC<UserContextProviderProps> = ({ children }) => {
  const ldClient = useLDClient()
  const medplum = useMedplum()
  const profile = medplum.getProfile()
  const profileName = formatPrimaryName(profile?.name) ?? ''
  const profileEmail = profile?.telecom?.find((t) => t.system === 'email')?.value
  const practitionerId = profile?.id

  useEffect(() => {
    if (ldClient && practitionerId && profileName && profileEmail) {
      ldClient.identify({
        kind: 'user',
        key: practitionerId,
        name: profileName,
        email: profileEmail
      })
    }
  }, [practitionerId, ldClient, profileEmail, profileName])

  return children
}

export default FeatureFlagUserContextProvider
