import { QueryClient } from '@tanstack/react-query'

export const A_MINUTE_IN_MS = 60 * 1000

const DEFAULT_STALE_TIME = 0

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: DEFAULT_STALE_TIME,
      refetchOnWindowFocus: false
    }
  }
})
