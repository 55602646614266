import '../styles.css'
import React, { useEffect, useMemo } from 'react'
import { Inter } from '@next/font/google'
import { ConfigProvider } from 'antd'
import { MedplumProvider } from '@medplum/react'
import type { ReactElement, ReactNode } from 'react'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advanced from 'dayjs/plugin/advancedFormat'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import isBetween from 'dayjs/plugin/isBetween'
import duration from 'dayjs/plugin/duration'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import * as Sentry from '@sentry/nextjs'
import { FullStory, init as initFullstory } from '@fullstory/browser'
import Head from 'next/head'
import { QueryClientProvider } from '@tanstack/react-query'
import FeatureFlagProvider from 'providers/FeatureFlagProvider'
import FeatureFlagUserContextProvider from 'providers/FeatureFlagUserContextProvider'
import { medplum } from 'utils/medplumClient'
import { NotificationProvider } from 'contexts/NotificationContext'
import useSafeNavigation from 'hooks/useSafeNavigation'
import { queryClient } from 'clients/reactQuery'
import favicon from '../public/assets/favicon.png'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advanced)
// Needed for AntD Datepicker
// https://github.com/ant-design/ant-design/issues/26190#issuecomment-703673400
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(isBetween)
dayjs.extend(duration)
dayjs.extend(localizedFormat)
dayjs.extend(weekOfYear)
dayjs.extend(advancedFormat)

const inter = Inter({
  subsets: ['latin'],
  weight: ['100', '300', '400', '500', '700', '900']
})

const theme = {
  token: {
    fontFamily: inter.style.fontFamily,
    colorBgContainer: '#ffffff'
  }
}

// https://nextjs.org/docs/pages/building-your-application/routing/pages-and-layouts#with-typescript
export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

// const TOKEN_REFRESH_INTERVAL_MS = 30 * 60 * 1000 // 30 minutes
// const TOKEN_REFRESH_GRACE_PERIOD_MS = 60 * 60 * 1000 // 60 minutes to avoid any gaps

const App = ({ Component, pageProps }: AppPropsWithLayout): any => {
  const { asPath } = useRouter()
  const { safeReplace } = useSafeNavigation()
  const isLoading = medplum.isLoading()
  const profile = medplum.getProfile()

  const isWindow = typeof window !== 'undefined'

  // Q: Will this actually refresh? What happens if it attempts to refresh if expired, finds it's not expired, and then doesn't refresh until the token is completely gone?
  // A: TOKEN_REFRESH_GRACE_PERIOD_MS is a grace period until the token expires, medplum token expiry time is 60 minutes, setting grace period to 60 minutes avoids any edge or corner case.

  // Q: Can we set a Timeout to refresh it 1 minute or 5 minutes or whatever before the token actually expires
  // A: There's no such information being exposed at the client side, we don't know when a token actually expires from client.

  // adding more context, If Remaining expiration time - grace period > 0, medium won’t issue a new token

  // TODO: revert when medplum fix is in
  // useEffect(() => {
  //   const handlePageLoad = async (): Promise<void> => {
  //     if (!medplum?.refreshIfExpired) {
  //       captureException(new Error('medplum.refreshIfExpired is not defined'), {
  //         tags: { source: SentrySources.MEDPLUM_INITIALIZATION },
  //         extras: { params: { user: profile } }
  //       })
  //       return
  //     }
  //     await medplum.refreshIfExpired(TOKEN_REFRESH_GRACE_PERIOD_MS)
  //   }

  //   const interval = setInterval(() => {
  //     handlePageLoad()
  //   }, TOKEN_REFRESH_INTERVAL_MS)

  //   // Cleanup the interval when component unmounts
  //   return () => clearInterval(interval)
  // }, [])

  useEffect(() => {
    if (!isLoading && isWindow && !profile && asPath !== '/login') {
      safeReplace('/login')
    }
  }, [isLoading, isWindow, profile, asPath, safeReplace])

  const profileUserEmail = profile?.telecom?.find((t) => t.system === 'email')?.value
  const practitionerId = profile?.id

  useEffect(() => {
    Sentry.setUser({ email: profileUserEmail, practitionerId })
  }, [profileUserEmail, practitionerId])

  const fullStoryOrgId = process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID

  useEffect(() => {
    if (fullStoryOrgId) {
      initFullstory({
        orgId: fullStoryOrgId,
        devMode: process.env.NODE_ENV !== 'production'
      })

      if (practitionerId && profileUserEmail) {
        FullStory('setIdentity', {
          uid: practitionerId.toString(),
          properties: {
            email: profileUserEmail
          }
        })
      }
    }
  }, [fullStoryOrgId, practitionerId, profileUserEmail])

  const getLayout = Component.getLayout ?? ((page) => page)

  // Parse the last part of the URL to use as the tab title.
  // Capitalize the first letter of each word except for acronyms like CNA.
  const { capitalizedTabTitle } = useMemo(() => {
    const tabTitle = asPath.split('/').pop()?.replace(/-/g, ' ')
    const capitalizedTabTitle = tabTitle?.replace(/cna|\b\w/g, (char) => char.toUpperCase())
    return { capitalizedTabTitle }
  }, [asPath])

  return (
    <ConfigProvider theme={theme}>
      <MedplumProvider medplum={medplum}>
        <FeatureFlagProvider>
          <NotificationProvider>
            <QueryClientProvider client={queryClient}>
              <FeatureFlagUserContextProvider>
                <>
                  <Head>
                    <link rel="icon" href={favicon.src} />
                    <title>AbbyOS - {capitalizedTabTitle}</title>
                  </Head>
                  {getLayout(<Component {...pageProps} />)}
                </>
              </FeatureFlagUserContextProvider>
            </QueryClientProvider>
          </NotificationProvider>
        </FeatureFlagProvider>
      </MedplumProvider>
    </ConfigProvider>
  )
}

export default App
