import { notification } from 'antd'
import { ArgsProps, NotificationConfig } from 'antd/es/notification/interface'
import { createContext, FC } from 'react'

interface NotificationProviderProps {
  children: React.ReactNode
}

export enum NOTIFY_KEYS {
  PAT_VALIDATION_ERROR = 'PAT_VALIDATION_ERROR',
  SOC_VALIDATION_ERROR = 'SOC_VALIDATION_ERROR',
  RECERT_VALIDATION_ERROR = 'RECERT_VALIDATION_ERROR',
  REVISION_OF_PAR_VALIDATION_ERROR = 'REVISION_OF_PAR_VALIDATION_ERROR',
  DISCHARGE_VALIDATION_ERROR = 'DISCHARGE_VALIDATION_ERROR',
  ROC_VALIDATION_ERROR = 'ROC_VALIDATION_ERROR',
  ASSESSMENT_FINAL_ROUND_QA = 'ASSESSMENT_FINAL_ROUND_QA',
  SCHEDULE_CONFLICTS = 'SCHEDULE_CONFLICTS',
  PAT_GENERATION_ERROR = 'PAT_GENERATION_ERROR',
  SKILLED_NEEDS_REGENERATED = 'SKILLED_NEEDS_REGENERATED',
  RESOURCE_GENERATION_NOTIFICATION = 'RESOURCE_GENERATION_NOTIFICATION',
  ORDER_CREATION_NOTIFICATION = 'ORDER_CREATION_NOTIFICATION',
  ORDER_RESULT_NOTIFICATION = 'ORDER_RESULT_NOTIFICATION',
  DOCUMENTS_VALIDATION_ERROR = 'DOCUMENTS_VALIDATION_ERROR',
  SUPERVISORY_VALIDATION_ERROR = 'SUPERVISORY_VALIDATION_ERROR'
}

export const NotificationContext = createContext({
  notify: {
    success: (_: ArgsProps) => {},
    warning: (_: ArgsProps) => {},
    error: (_: ArgsProps) => {},
    info: (_: ArgsProps) => {},
    destroy: (_: string) => {}
  }
})

export const NotificationProvider: FC<NotificationProviderProps> = ({ children }) => {
  const notificationConfig: NotificationConfig = {}

  const messageConfig: Partial<ArgsProps> = {
    placement: 'bottomRight',
    duration: 5
  }

  const NOTIFY_ALLOWED_KEYS = [
    NOTIFY_KEYS.PAT_VALIDATION_ERROR,
    NOTIFY_KEYS.SOC_VALIDATION_ERROR,
    NOTIFY_KEYS.RECERT_VALIDATION_ERROR,
    NOTIFY_KEYS.REVISION_OF_PAR_VALIDATION_ERROR,
    NOTIFY_KEYS.ASSESSMENT_FINAL_ROUND_QA,
    NOTIFY_KEYS.SCHEDULE_CONFLICTS,
    NOTIFY_KEYS.PAT_GENERATION_ERROR,
    NOTIFY_KEYS.RESOURCE_GENERATION_NOTIFICATION,
    NOTIFY_KEYS.ORDER_RESULT_NOTIFICATION,
    NOTIFY_KEYS.DOCUMENTS_VALIDATION_ERROR,
    NOTIFY_KEYS.SUPERVISORY_VALIDATION_ERROR
  ]

  const [api, contextHolder] = notification.useNotification(notificationConfig)

  const hydrateMessage = (message: ArgsProps): ArgsProps => {
    if (message.key && !NOTIFY_ALLOWED_KEYS[message.key.toString()]) {
      console.warn(
        `Notification key '${message.key}' not defined in allowed notification keys. Add key to NOTIFY_ALLOWED_KEYS`
      )
    }

    return {
      ...messageConfig,
      ...message
    }
  }

  const success = (message: ArgsProps): void => {
    api.success(hydrateMessage(message))
  }

  const error = (message: ArgsProps): void => {
    api.error(hydrateMessage(message))
  }

  const warning = (message: ArgsProps): void => {
    api.warning(hydrateMessage(message))
  }

  const info = (message: ArgsProps): void => {
    api.info(hydrateMessage(message))
  }

  const destroy = (key: string): void => {
    if (!NOTIFY_ALLOWED_KEYS[key]) {
      console.warn(
        `Notification key '${key}' not defined in allowed notification keys. It's possible the notification you're trying to dismiss does not exist. Add key to NOTIFY_ALLOWED_KEYS`
      )
    }

    api.destroy(key)
  }

  return (
    <NotificationContext.Provider
      value={{
        notify: {
          success,
          error,
          warning,
          info,
          destroy
        }
      }}
    >
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  )
}
