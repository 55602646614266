import * as Sentry from '@sentry/nextjs'

export enum TransactionNames {
  CREATE_NOTE = 'CreateNote',
  GET_NOTES_BY_PATIENT = 'GetNotesByPatient',
  FETCH = 'Fetch',
  REGENERATE_ASSESSMENT_DOC = 'RegenerateAssessmentDoc',
  SUBMIT_ASSESSMENT = 'SubmitAssessment'
}

export enum SentrySources {
  MEDPLUM_FETCH_MIDDLEWARE = 'medplum-fetch-middleware',
  FASTAPI_FETCH_MIDDLEWARE = 'fastapi-fetch-middleware',
  MEDPLUM_GRAPHQL_QUERY = 'medplum-graphql-query',
  LOGIN = 'login',
  NAV_BAR = 'nav-bar',
  FORMAT_NOTE_DATA = 'format-note-data',
  MAIN_WRAPPER = 'main-wrapper',
  PATIENT_LAYOUT = 'patient-layout',
  EMPLOYEE_LAYOUT = 'employee-layout',
  RECERT_ASSESSMENT = 'recert-assessment',
  USE_PATIENT_NOTES = 'use-patient-notes',
  PATIENT_ASSESSMENTS = 'patient-assessments',
  SCHEDULE_GENERATION_WARNING = 'schedule-generation-warning',
  PATIENT_CARE_STATUS = 'patient-care-status',
  PATIENT_NOTES = 'patient-notes',
  ASSESSMENTS = 'assessments',
  TASKS = 'tasks',
  CNA_VISITS_QA = 'cna-visits-qa',
  DOCUMENTS = 'documents',
  TABLE = 'table',
  ORDERS = 'orders',
  PATIENT_CHART = 'patient-chart',
  HELPERS = 'helpers',
  VISITS = 'visits',
  SCHEDULING = 'scheduling',
  PROVIDER_MODAL = 'provider-modal',
  ENCOUNTER_SLICE = 'encounter-slice',
  LOCATION_SLICE = 'location-slice',
  ORDERS_SLICE = 'orders-slice',
  PATIENT_SLICE = 'patient-slice',
  PRACTITIONER_SLICE = 'practitioner-slice',
  SCHEDULE_GENERATION = 'schedule-generation-warning',
  PATIENT_ONBOARDING = 'patient-onboarding',
  EMPLOYEE_BACKGROUND = 'employee-background',
  CAREGIVER_ONBOARDING = 'caregiver-onboarding',
  FEATURE_CONFIGS = 'feature-configs',
  MEDPLUM_INITIALIZATION = 'medplum-initialization',
  FLOWS = 'flows'
}

type Tags = {
  source: string
  operational?: string
  url?: string
}

type Extras = {
  params?: any
  resources?: any
  query?: any
  info?: any
  errors?: [Record<string, any>]
}

export type SentryOptions = {
  transactionName?: TransactionNames
  tags?: Tags
  extras?: Extras
}

const transformExtrasToJSON = (extras: Extras): Record<string, string> => {
  const transformed: Record<string, string> = {}

  for (const key in extras) {
    const value = extras[key]

    // Transform the value to a JSON string
    transformed[key] = JSON.stringify(value)
  }

  return transformed
}

export const captureException = (error: Error, SentryOptions?: SentryOptions): void => {
  const { transactionName, tags, extras } = SentryOptions || {}
  if (process.env.NODE_ENV !== 'development') {
    Sentry.withScope((scope) => {
      if (transactionName) {
        scope.setTransactionName(String(transactionName))
      }
      if (tags) {
        Object.keys(tags).forEach((key) => {
          scope.setTag(key, tags[key] || undefined)
        })
      }
      if (extras) {
        scope.setExtras(transformExtrasToJSON(extras))
      }
      Sentry.captureException(error)
    })
  } else {
    // eslint-disable-next-line no-console
    console.error('Sentry Error:', error, SentryOptions)
  }
}
