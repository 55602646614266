import { LDProvider } from 'launchdarkly-react-client-sdk'
import { FC } from 'react'

interface FeatureFlagProviderProps {
  children: React.ReactNode
}

const FeatureFlagProvider: FC<FeatureFlagProviderProps> = ({ children }) => {
  const ldClientSideID = process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_ID

  return ldClientSideID ? (
    <LDProvider clientSideID={ldClientSideID} reactOptions={{ useCamelCaseFlagKeys: false }}>
      {children}
    </LDProvider>
  ) : (
    <></>
  )
}

export default FeatureFlagProvider
