var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"Bb5GdE9IqeIUrGUEtcujS"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { IS_PRODUCTION } from 'utils/constants'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

// this is not a TS file
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getEnvironment = () => {
  let isStaging = false

  if (typeof window !== 'undefined') {
    isStaging = window.location.hostname.includes('abbycare-staging')
  }

  if (isStaging) {
    return 'staging'
  } else if (IS_PRODUCTION) {
    return 'production'
  }

  return 'development'
}

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: getEnvironment(),
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Sentry.Replay()]
  })
}
